require("typeface-poppins");
require("assets/fonts/butler/main.css");

const isEnabled = () =>
  process.env.NODE_ENV === `production` &&
  typeof Intercom === `function` &&
  window.IntercomAppId;

exports.onInitialClientRender = () => {
  if (!isEnabled()) {
    return;
  }

  window.Intercom("boot", {
    app_id: window.IntercomAppId,
  });
};

exports.onRouteUpdate = function () {
  if (!isEnabled()) {
    return;
  }

  window.Intercom("update");
};
